/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Beatriz & João - Presentes"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pt--80" name={"contact"} style={{"paddingBottom":75}} border={null} layout={"l13"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--0 pr--0 flex--center" anim={null} animS={null} style={{"maxWidth":1080}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center w--300 pt--50" content={"Presentes de Casamento"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60" name={"ptxzjd4um3e"} style={{"paddingTop":27}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--24" style={{"maxWidth":886}} content={"<span style=\"color: var(--color-blend--15);\">Deixamos o nosso IBAN caso queiram contribuir para o início desta grande viagem a dois… começa na lua-de-mel e vai durar o resto da vida! <br>Muito obrigado!</span><br>"}>
              </Text>

              <Title className="title-box title-box--center fs--18 mt--06" style={{"paddingTop":52}} content={"<span style=\"color: var(--color-blend--50);\">IBAN: PT50 0033 0000 45569543477 05</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}